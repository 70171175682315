import { defaultCountries, parseCountry } from 'react-international-phone';

export const API_URL = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const objectContaminations = [
  { label: 'contamination_lint_fiber', labelToShow: 'Lint/Fiber', color: 'green' },
  { label: 'contamination_dirt', labelToShow: 'Dirt', color: 'yellow' },
  { label: 'damage_deformed', labelToShow: 'Deformation', color: 'blue' },
  { label: 'damage_scratch', labelToShow: 'Scratch', color: 'black' },
  { label: 'contamination_fluid', labelToShow: 'Fluid', color: 'pink' },
  { label: 'damage_peeling', labelToShow: 'Peeling', color: 'orange' },
  { label: 'contamination_stain', labelToShow: 'Stain', color: 'purple' },
  { label: 'contamination_unknown', labelToShow: 'Unknown', color: 'brown' }
];
export const domainMax = 60;

export const marginBottom = 30;
export const xOffset = 20;
export const imageGap = 2;
export const contaminationsQuantatity = 8;

export const maxVideoSize = 2 * 1024 * 1024 * 1024; //2GB

export const contaminationOptions = [
  { value: 'contamination_lint_fiber', label: 'Lint/Fiber' },
  { value: 'contamination_dirt', label: 'Dirt' },
  { value: 'damage_deformed', label: 'Deformation' },
  { value: 'damage_scratch', label: 'Scratch' },
  { value: 'contamination_fluid', label: 'Fluid' },
  { value: 'damage_peeling', label: 'Peeling' },
  { value: 'contamination_stain', label: 'Stain' },
  { value: 'contamination_unknown', label: 'Contamination unknown' }
];

export const doubleArrowDeltaTime = 200;

export const contaminationListWidth = 120;
export const contaminationListHeight = 250;
export const contaminationCloseWidth = 19;

export const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return ['at', 'be', 'ca', 'de', 'us', 'lu', 'nl', 'gb', 'fr', 'ch'].includes(iso2);
});

export const countriesOptions = [
  { value: 'at', label: 'Austria', code: '43' },
  { value: 'be', label: 'Belgium', code: '32' },
  { value: 'ca', label: 'Canada', code: '1' },
  { value: 'fr', label: 'France', code: '33' },
  { value: 'de', label: 'Germany', code: '49' },
  { value: 'lu', label: 'Luxembourg', code: '352' },
  { value: 'nl', label: 'Netherlands', code: '31' },
  { value: 'ch', label: 'Switzerland', code: '41' },
  { value: 'gb', label: 'United Kingdom', code: '44' },
  { value: 'us', label: 'United States', code: '1' }
];
