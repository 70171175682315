import { format } from 'date-fns';
import { enUS, fr, de } from 'date-fns/locale';

export function getLocale(lang) {
  switch (lang) {
    case 'fr':
      return fr;
    case 'de':
      return de;
    default:
      return enUS;
  }
}

export function formatMonthDayYear(value, lang) {
  return value ? format(new Date(value), 'PP', { locale: getLocale(lang) }) : '';
}
export function formatMonthDayYearWithHours(value, lang) {
  return value ? format(new Date(value), 'PPp', { locale: getLocale(lang) }) : '';
}

export function formatYearMonthDayWithHours(value) {
  return value ? format(new Date(value), 'yyyy-MM-dd HH:mm') : '';
}
