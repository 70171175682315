export function getCoordinates(partialValue, totalValue) {
  return convertToFixedNumber((partialValue += 0) / totalValue, 4);
}

export const generateRandomNumber = () => {
  return Math.floor(Math.random() * 900000) + 100000;
};

export const convertToFixedNumber = (value, decimals) => {
  return +parseFloat(value)
    .toFixed(decimals)
    .replace(/\.?0+$/, '');
};
