import * as d3 from 'd3';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  domainMax,
  marginBottom,
  xOffset,
  contaminationsQuantatity,
  imageGap
} from '../../helpers/constants';

const Graph = ({
  data,
  type,
  defaultHeight,
  record_mode,
  isLast,
  handleContaminationClick,
  width,
  timeIndicator = 0,
  istimeIndicatorVisible
}) => {
  const { t } = useTranslation('common');

  const imageSize = (width - xOffset * 2) / domainMax - imageGap;
  const height =
    defaultHeight ||
    imageSize * contaminationsQuantatity + imageGap * contaminationsQuantatity * 2 + marginBottom;
  const chartBottomY = height - marginBottom;
  const order = domainMax * type;
  const adjustPosition = order - domainMax;
  const xScale = d3
    .scaleLinear()
    .domain([adjustPosition, order])
    .range([xOffset, width - xOffset]);

  const xAxis = d3
    .axisBottom(xScale)
    .ticks(30)
    .tickFormat((x) => (x % 4 === 0 || x == 0 ? x : ''));

  // Create the vertical scale.
  const yScale = d3
    .scaleLinear()
    .domain([0, contaminationsQuantatity])
    .range([height - marginBottom, 0]);

  useEffect(() => {
    d3.select(`.x-axis-${type}`).call(xAxis);

    // d3.select('.y-axis').call(yAxis).selectAll('text').attr('font-size', '14px');
  }, [xAxis]);

  return (
    <>
      {width > 0 && (
        <>
          <svg
            className={`contimination-map_${type}`}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
          >
            <>
              {istimeIndicatorVisible &&
                timeIndicator >= adjustPosition &&
                (isLast ? timeIndicator <= order : timeIndicator < order) && (
                  <g key={`timeIndicator-${type}`}>
                    <line
                      x1={xScale(timeIndicator)}
                      y1={chartBottomY}
                      x2={xScale(timeIndicator)}
                      y2="0"
                      stroke="red"
                    />
                  </g>
                )}

              {Object.keys(data).map((sec, i) =>
                Object.keys(data[sec]).map((damageType, position) => (
                  <g key={`${data[sec][damageType]}-${position}`}>
                    <image
                      className="cursor-pointer	"
                      onClick={() => {
                        console.log('clicked image', data[sec][damageType]);
                        handleContaminationClick(data[sec][damageType].frame);
                      }}
                      key={`${data[sec][damageType]}-${position}_image`}
                      href={`/${data[sec][damageType].label}.png`}
                      height={imageSize}
                      width={imageSize}
                      x={xScale(+sec)}
                      y={yScale(position + 1)}
                    />
                  </g>
                ))
              )}
            </>
            <g className={`x-axis-${type}`} transform={`translate(0,${chartBottomY})`}></g>
          </svg>
          {isLast && (
            <div className="text-center text-sm	font-medium">
              {record_mode === 'Auto' ? t('common:lengthEndoscope') : t('common:timeVideo')}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Graph;
