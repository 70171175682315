import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import {
  TbPlayerPlayFilled,
  TbPlayerPauseFilled,
  TbPlayerSkipBackFilled,
  TbPlayerSkipForwardFilled,
  TbPlayerTrackPrevFilled,
  TbPlayerTrackNextFilled,
  TbScanEye
} from 'react-icons/tb';
import { Button } from '@material-tailwind/react';

import {
  reportModeAtom,
  framesAtom,
  frameAtom,
  isReportingAtom,
  isPausedAtom,
  targetFrameAtom,
  reportFramesAtom,
  mediaTimeAtom
} from '../../atoms/video';
import { endoscopesAtom, endoscopeHistoryAtom, loadingAtom } from '../../atoms/endoscopes';
import { fetchEndoscopeHistory } from '../../api/endoscopes';
import { generateReport, saveDraft } from '../../api/reports';
import { getUser } from '../../utils/session';
import { getVideoId, jumpNextFrame, binarySearch } from '../../utils/helpers';
import { doubleArrowDeltaTime } from '../../helpers/constants';
import SpeedButton from '../SpeedButton';

const Controls = ({ handleLoadMetadata, videoRef }) => {
  const user = getUser();
  const { t } = useTranslation(['buttons']);
  const [data, setData] = useAtom(endoscopesAtom);
  const [frames, setFrames] = useAtom(framesAtom);
  const [mediaTime, setMediaTime] = useAtom(mediaTimeAtom);
  const [frame, setFrame] = useAtom(frameAtom);
  const [reportMode, setReportMode] = useAtom(reportModeAtom);
  const [isReporting, setIsReporting] = useAtom(isReportingAtom);
  const [history, setHistory] = useAtom(endoscopeHistoryAtom);
  const [isLoading, setIsLoading] = useAtom(loadingAtom);
  const [isPaused, setIsPaused] = useAtom(isPausedAtom);
  const [targetFrame, setTargetFrame] = useAtom(targetFrameAtom);
  const [retarget, setRetarget] = useState(false);
  const [reportFrames, setReportFrames] = useAtom(reportFramesAtom);

  const videoId = getVideoId();
  const { fps, endoscope } = data;
  const endoscopeId = endoscope?.id;
  let leftArrowLastPressTime = 0;
  let rightArrowLastPressTime = 0;
  const currentFrames = reportMode ? frames : reportFrames?.frames;
  const framesKeys = useMemo(() => {
    return Object.keys(currentFrames);
  }, [currentFrames]);

  const cleanBoxesWithoutLabels = () => {
    return setFrames((prevFrames) => ({
      ...prevFrames,
      [frame]: frames[frame]?.filter((item) => item.label)
    }));
  };

  const handleArrowSpeedChange = (key) => {
    if (key === 'SpeedUp' && videoRef.current.playbackRate < 4) {
      const step = videoRef.current.playbackRate < 2 ? 0.25 : 1;
      videoRef.current.playbackRate += step;
    }
    if (key === 'SpeedDown' && videoRef.current.playbackRate > 0.25) {
      const step = videoRef.current.playbackRate <= 2 ? 0.25 : 1;
      videoRef.current.playbackRate -= step;
    }
    handleLoadMetadata();
  };

  const setVideoToFrame = (frame) => {
    if (videoRef?.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }

      setTargetFrame(+frame);
      const time = +frame / fps;

      videoRef.current.currentTime = time;
    }
  };

  const nextDetection = (direction) => {
    if (isReporting && frames[frame]) {
      cleanBoxesWithoutLabels();
    }

    const current = binarySearch(framesKeys, frame);

    const currentKey = framesKeys[current];

    const nextFrame =
      direction === 'back'
        ? framesKeys[frame > currentKey ? current : current - 1]
        : framesKeys[frame >= currentKey ? current + 1 : current];

    if (nextFrame) {
      setVideoToFrame(nextFrame);
    }
  };

  const nextFrame = (direction) => {
    if (isReporting && frames[frame]) {
      cleanBoxesWithoutLabels();
    }

    return jumpNextFrame(direction, mediaTime);
  };

  const handleDoubleArrowPress = (key) => {
    if (isReporting && frames[frame]) {
      cleanBoxesWithoutLabels();
    }
    handleLoadMetadata();

    return key === 'ArrowLeft'
      ? (videoRef.current.currentTime -= 5)
      : (videoRef.current.currentTime += 5);
  };

  const pauseFunction = () => {
    videoRef.current.pause();

    handleLoadMetadata();
  };

  const playFunction = () => {
    if (isReporting && frames[frame]) {
      setIsReporting(false);
      cleanBoxesWithoutLabels();
    }

    const isPlaying =
      videoRef.current.currentTime > 0 &&
      !videoRef.current.paused &&
      !videoRef.current.ended &&
      videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      videoRef.current.play();
      handleLoadMetadata();
    }
  };

  const handleGenerateReport = async () => {
    const filteredFrames = frames;
    Object.keys(filteredFrames).forEach((key) => {
      filteredFrames[key] = filteredFrames[key]?.filter((item) => item.label);
    });

    const data = { generated_by: user.user_id, frames: filteredFrames };
    try {
      await generateReport(data, videoId);
      handleExitReportMode();
      setIsLoading(true);
      const res = await fetchEndoscopeHistory(endoscopeId);
      setHistory(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('generateReport error', error);
    }
  };

  const handleSaveDraft = async () => {
    const filteredFrames = frames;
    Object.keys(filteredFrames).forEach((key) => {
      filteredFrames[key] = filteredFrames[key]?.filter((item) => item.label);
    });

    const data = { generated_by: user.user_id, frames: filteredFrames };
    try {
      await saveDraft(data, videoId);
      handleExitReportMode();
      setIsLoading(true);
      const res = await fetchEndoscopeHistory(endoscopeId);
      setHistory(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('generateReport error', error);
    }
  };

  //hide create new report button
  const changeReportStatus = () => {
    if (!reportMode && reportFrames.id) {
      setReportFrames({ id: null, frames: {} });
    }
    setReportMode((reportMode) => !reportMode);
  };

  const handleExitReportMode = () => {
    setFrames({});
    if (reportFrames.id) {
      setReportFrames({ id: null, frames: {} });
    }

    setReportMode(false);

    if (isReporting) {
      setIsReporting(false);
    }
  };

  const handleVideoKeyPress = (e) => {
    // console.log('handleVideoKeyPress', e.key);

    if (e.key === 'Escape') {
      if (isReporting) {
        setIsReporting(false);
      }
    }
    //space press
    if ((e.key == ' ' || e.keyCode === 32) && videoRef) {
      e.preventDefault();
      videoRef.current.paused ? playFunction() : pauseFunction();
    }

    if (e.key === 'w') {
      if (framesKeys?.length > 1) {
        nextDetection();
      }
    }
    if (e.key === 'a') {
      if (framesKeys?.length > 1) {
        nextDetection('back');
      }
    }

    if (e.key === 'ArrowRight') {
      const currentTime = new Date().getTime();
      currentTime - rightArrowLastPressTime > doubleArrowDeltaTime
        ? nextFrame()
        : handleDoubleArrowPress('ArrowRight');
      rightArrowLastPressTime = currentTime;
    }
    if (e.key === 'ArrowLeft') {
      const currentTime = new Date().getTime();
      currentTime - leftArrowLastPressTime > doubleArrowDeltaTime
        ? nextFrame('back')
        : handleDoubleArrowPress('ArrowLeft');
      leftArrowLastPressTime = currentTime;
    }

    if (e.key === 'ArrowUp') {
      handleArrowSpeedChange('SpeedUp');
    }
    if (e.key === 'ArrowDown') {
      handleArrowSpeedChange('SpeedDown');
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleVideoKeyPress);

    return () => {
      window.removeEventListener('keydown', handleVideoKeyPress);
    };
  }, [isReporting, framesKeys, frame]);

  useEffect(() => {
    if (targetFrame) {
      if (targetFrame === frame) {
        setTargetFrame(null);

        if (retarget) {
          setRetarget(false);
        }
      } else {
        setRetarget(true);
      }
    }
  }, [frame]);

  useEffect(() => {
    if (targetFrame) {
      targetFrame - frame ? jumpNextFrame('forward', mediaTime) : jumpNextFrame('back', mediaTime);
    }
  }, [targetFrame, retarget]);

  videoRef.current.onplaying = () => {
    setIsPaused(false);
  };
  videoRef.current.onpause = () => {
    setIsPaused(true);
  };

  return (
    <div className="flex flex-col video_container">
      <div className="flex flex-wrap w-full justify-between">
        <div className="flex pt-5">
          <SpeedButton onClick={() => nextFrame('back')}>
            <TbPlayerSkipBackFilled />
          </SpeedButton>
          {isPaused ? (
            <SpeedButton label={t('play')} onClick={playFunction}>
              <TbPlayerPlayFilled />
            </SpeedButton>
          ) : (
            <SpeedButton onClick={pauseFunction}>
              <TbPlayerPauseFilled />
            </SpeedButton>
          )}
          <SpeedButton customStyle="[&&]:mr-0" onClick={() => nextFrame()}>
            <TbPlayerSkipForwardFilled />
          </SpeedButton>
        </div>

        {framesKeys.length > 1 && (
          <div className="flex pt-5">
            <SpeedButton onClick={() => nextDetection('back')}>
              <TbScanEye />
              <TbPlayerSkipBackFilled />
            </SpeedButton>
            <SpeedButton customStyle="[&&]:mr-0" onClick={() => nextDetection()}>
              <TbPlayerSkipForwardFilled />
              <TbScanEye />
            </SpeedButton>
          </div>
        )}

        <div className="flex pt-5">
          <SpeedButton onClick={() => handleArrowSpeedChange('SpeedDown')}>
            <TbPlayerTrackPrevFilled />
          </SpeedButton>
          <SpeedButton customStyle="[&&]:mr-0" onClick={() => handleArrowSpeedChange('SpeedUp')}>
            <TbPlayerTrackNextFilled />
          </SpeedButton>
        </div>
      </div>
      <div className="test"></div>
      <div className="flex py-5 justify-between">
        {reportMode ? (
          <>
            <Button ripple={false} color="green" variant="gradient" onClick={handleGenerateReport}>
              {t('buttons:generateReport')}
            </Button>
            <Button ripple={false} color="green" variant="gradient" onClick={handleSaveDraft}>
              {t('buttons:saveDraft')}
            </Button>

            <Button ripple={false} color="red" variant="gradient" onClick={handleExitReportMode}>
              {t('buttons:cancel')}
            </Button>
          </>
        ) : (
          <Button
            ripple={false}
            disabled={!fps}
            onClick={changeReportStatus}
            color="light-blue"
            variant="gradient"
          >
            {t('buttons:reportMode')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Controls;
