import { FiMessageSquare } from 'react-icons/fi';
import { TiDocumentText } from 'react-icons/ti';

export const navListMenuItems = [
  {
    title: 'documentation',
    description: '',
    icon: TiDocumentText,
    url: '#'
  },
  {
    title: 'contactUs',
    description: '',
    icon: FiMessageSquare,
    url: '/contact-us'
  }
];
