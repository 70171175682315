import { useRef, useLayoutEffect } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { LuShare2 } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-tailwind/react';
import cls from 'classnames';

import Header from '../../components/Header';
import Notification from '../../components/Notification';
import VideoDetails from '../../components/VideoDetails';
import EndoscopeHistory from '../EndoscopeHistory';
import ContaminationMap from '../../components/ContaminationMap/ContaminationMap';
import { currentCompanyAtom } from '../../atoms/companies';
import { sidePanelAtom, widthAtom } from '../../atoms/app';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import SidePanel from '../../components/SidePanel/SidePanel';
import BackButton from '../../components/BackButton/BackButton';

function Video() {
  const navigate = useNavigate();
  const containerRef = useRef();
  const { t } = useTranslation(['buttons']);
  const [selectedCompany] = useAtom(currentCompanyAtom);
  const [isSidePanelOpen, setIsSidePanelOpen] = useAtom(sidePanelAtom);
  const [width, setWidth] = useAtom(widthAtom);

  const clickEvent = (e) => {
    navigate(`/videos/${selectedCompany}`, { replace: true });
  };

  useLayoutEffect(() => {
    const { width } = containerRef.current.getBoundingClientRect();
    setWidth(width);
  }, [isSidePanelOpen]);

  return (
    <>
      <Header></Header>
      {<Notification isTempopary={true} />}
      <div className={cls('grid grid-cols-10')}>
        <div
          ref={containerRef}
          className={cls('col-span-6 pl-4 pr-4', { '[&&]:col-span-4': isSidePanelOpen })}
        >
          <div className="flex justify-between">
            <BackButton customHandler={clickEvent} />
            <Button
              ripple={false}
              variant="gradient"
              color={'light-blue'}
              className="flex items-center rounded-2xl my-2"
              onClick={() => setIsSidePanelOpen(!isSidePanelOpen)}
            >
              <span className="mr-2">{t('buttons:share')}</span>
              <LuShare2 className="h-4 w-4" />
            </Button>
          </div>

          <VideoDetails />
          <EndoscopeHistory />

          <ContaminationMap width={width} />
        </div>
        <div className={cls('pr-4 col-span-4', { '[&&]:col-span-3': isSidePanelOpen })}>
          <VideoPlayer isSidePanelOpen={isSidePanelOpen} />
        </div>
        <div className="col-span-3">{isSidePanelOpen && <SidePanel />}</div>
      </div>
    </>
  );
}

export default Video;
