import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  framesAtom,
  reportFramesAtom,
  reportModeAtom,
  isReportingAtom,
  targetFrameAtom,
  mediaTimeAtom
} from '../../atoms/video';
import { endoscopesAtom } from '../../atoms/endoscopes';
import { loadingReportsAtom } from '../../atoms/reports.js';
import { domainMax, objectContaminations } from '../../helpers/constants';
import Graph from './Graph';
import { formContaminationData, convertRemToPixels } from '../../utils/helpers.js';
import GraphLegend from './GraphLegend';
import Loader from '../Loader.js';

const ContaminationMap = ({ width }) => {
  const { t } = useTranslation('common');
  const [frames, setFrames] = useAtom(framesAtom);
  const [mediaTime, setMediaTime] = useAtom(mediaTimeAtom);
  const [reportFrames] = useAtom(reportFramesAtom);
  const [isReportsLoading, setIsReportsLoading] = useAtom(loadingReportsAtom);
  const [isReporting, setIsReporting] = useAtom(isReportingAtom);
  const [reportMode, setReportMode] = useAtom(reportModeAtom);
  const [targetFrame, setTargetFrame] = useAtom(targetFrameAtom);
  const [data] = useAtom(endoscopesAtom);
  const { fps, record_mode = '', record_speed = '' } = data;

  const currentFrames = reportMode ? frames : reportFrames?.frames;

  const chartData = useMemo(
    () => formContaminationData(currentFrames, fps, domainMax, record_mode, +record_speed),
    [currentFrames, fps, record_mode, record_speed]
  );
  const chartDataKeys = useMemo(() => Object.keys(chartData), [chartData]);
  const video = document.querySelector('video');
  const timeIndicator = record_mode === 'Auto' ? (mediaTime * +record_speed) / 10 : mediaTime;
  const widthMinusPadding = width - convertRemToPixels(6);

  const cleanBoxesWithoutLabels = (frame) => {
    return setFrames((prevFrames) => ({
      ...prevFrames,
      [frame]: frames[frame]?.filter((item) => item.label)
    }));
  };

  const handleContaminationClick = (frame) => {
    if (video) {
      if (isReporting && frames[frame]) {
        cleanBoxesWithoutLabels(frame);
      }
      if (!video.paused) {
        video.pause();
      }
      setTargetFrame(+frame);
      video.currentTime = +frame / fps;
    }
  };

  return (
    <fieldset className="mt-4 mb-4 p-6 border-2 rounded-4xl border-neutral-100 border-opacity-100">
      <legend className="text-blue-main font-semibold	px-1">{t('common:contaminationMap')}</legend>
      <GraphLegend data={objectContaminations} />
      {isReportsLoading && <Loader />}
      {!isReportsLoading && (
        <>
          {chartDataKeys.length > 0 ? (
            chartDataKeys.map((key, i) => (
              <Graph
                reportMode={reportMode}
                timeIndicator={timeIndicator}
                width={widthMinusPadding}
                handleContaminationClick={handleContaminationClick}
                isLast={chartDataKeys.length - 1 === i}
                record_mode={record_mode}
                key={key}
                type={+key + 1}
                data={chartData[key]}
                istimeIndicatorVisible={true}
              />
            ))
          ) : (
            <Graph
              reportMode={reportMode}
              width={widthMinusPadding}
              isLast={true}
              record_mode={record_mode}
              key={'empty'}
              istimeIndicatorVisible={false}
              type={1}
              data={{}}
              defaultHeight={!reportMode && 100}
            />
          )}
        </>
      )}
    </fieldset>
  );
};

export default ContaminationMap;
