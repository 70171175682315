const GraphLegend = ({ data }) => {
  return (
    <div className="flex justify-center flex-wrap px-8 mb-1">
      {data.map((d, i) => {
        return (
          <div key={d.label} className="flex items-center mr-8 mb-4">
            <img src={`/${d.label}.png`} height={20} width={20} className="mr-2" alt={d.label} />
            <span className="font-medium	text-base">{` - ${d.labelToShow.toUpperCase()}`}</span>
          </div>
        );
      })}
    </div>
  );
};

export default GraphLegend;

/* <g>
        {data.map((d, i) => {
          const x = i * 150;
          const y = 20;

          return (
            <g key={d.label}>
              <image href={`/${d.label}.png`} height={20} width={20} x={x} y={y} />
              <text x={x + 70} y={y + 10} dominant-baseline="middle" text-anchor="middle">
                {` - ${d.labelToShow}`}
              </text>
            </g>
          );
        })}
      </g> */
