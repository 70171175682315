import { useState, useMemo, useEffect } from 'react';
import { Checkbox } from '@material-tailwind/react';
import ReactSelect from 'react-select';
import { useAtom } from 'jotai';
import DateTimePicker from 'react-datetime-picker';
import { useTranslation } from 'react-i18next';

import { loadableUsersAtom } from '../../atoms/users';
import { loadableVideosAtom } from '../../atoms/video';
import { currentCompanyAtom } from '../../atoms/companies';
import { tableFiltersAtom, tableFiltersInitialState } from '../../atoms/filters';
import {
  setSelectValue,
  formUserOptions,
  createReletionEndoscope,
  formOwnerOptions,
  formSelectOptions
} from '../../utils/helpers';
import { contaminationOptions } from '../../helpers/constants';

const Filters = ({ companyType }) => {
  const { t } = useTranslation('common');
  const [filters, setFilters] = useAtom(tableFiltersAtom);
  const [users] = useAtom(loadableUsersAtom);
  const [selectedCompany, setSelectedCompany] = useAtom(currentCompanyAtom);
  const [models, setModels] = useState([]);
  const [serials, setSerials] = useState([]);
  const [producers, setProducers] = useState([]);
  const [selectedContamination, setSelectedContamination] = useState('');
  const [videos] = useAtom(loadableVideosAtom);
  const devices = videos?.data?.devices;
  const endoscopes = videos?.data?.endoscopes;
  const owners = videos?.data?.owners;

  const relationEndoscope = useMemo(
    () => createReletionEndoscope(endoscopes, setProducers, setModels, setSerials),
    [endoscopes]
  );

  // Function to handle selection of model
  const handleModelChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    handleFiltersChange(selectedValue, 'selectedModel');

    let producersList = '';
    let serialsList;

    relationEndoscope.models.forEach((item) => {
      if (item.model === selectedValue) {
        producersList = item.producer;
        serialsList = item.serials;
      }
    });
    setSerials(serialsList);

    if (serialsList === undefined) {
      setSerials([]);
    }
  };

  // Function to handle selection of serial
  const handleSerialChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    handleFiltersChange(selectedValue, 'selectedSerial');

    let producersList = [];
    let modelsList = [];

    relationEndoscope.serials.forEach((item) => {
      if (item.serial === selectedOption) {
        producersList.push(item.producer);
        modelsList.push(item.model);
      }
    });

    setProducers(producersList);
    setModels(modelsList);
  };
  //Handle contomination
  const handleContaminationsSelect = (selectedOption) => {
    setSelectedContamination(selectedOption);

    let contaminationList = '';
    selectedOption.forEach((contaminationItem, index) => {
      if (index > 0) {
        contaminationList += '%3B' + contaminationItem.value;
      } else {
        contaminationList += contaminationItem.value;
      }
    });
    handleFiltersChange(contaminationList, 'contaminationPath');
  };

  const handleFiltersChange = (data, type) => {
    setFilters({ ...filters, [type]: data });
  };

  const handleProducerChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    handleFiltersChange(selectedValue, 'selectedProducer');
    if (selectedOption) {
      let modelsList;
      let serialsList;

      relationEndoscope.producers.forEach((item) => {
        if (item.producer === selectedValue) {
          modelsList = item.models;
          serialsList = item.serials;
        }
      });
      setModels(modelsList);
      setSerials(serialsList);
    }
  };

  const producersOptions = (producers) => {
    const arr = [];
    producers.forEach((producer) => {
      arr.push({ value: producer, label: producer });
    });
    return arr;
  };

  const resetFilters = (selectedCompany) => {
    setSelectedContamination('');
    setFilters({ ...tableFiltersInitialState, currentCompany: selectedCompany });
  };

  useEffect(() => {
    if (selectedCompany) {
      resetFilters(selectedCompany);
    }

    return () => {
      if (selectedCompany) {
        resetFilters(selectedCompany);
      }
    };
  }, [selectedCompany]);

  const recorderOptionsData = useMemo(
    () => formUserOptions(users?.data?.filter((c) => c.user_type === 'app_user')),
    [users?.data]
  );
  const reporterOptionsData = useMemo(() => formUserOptions(users?.data), [users?.data]);
  const producersOptionsData = useMemo(() => producersOptions(producers), [producers]);

  const modelsOptionsData = useMemo(() => formSelectOptions(models), [models]);
  const serialsOptionsData = useMemo(() => formSelectOptions(serials), [serials]);
  const ownersOptionsData = useMemo(() => formOwnerOptions(owners), [owners]);

  return (
    <div className="mr-4 ml-2 p-4 max-w-xs">
      <div className="mb-2">
        <div className="pb-2">{t('common:from')}:</div>
        <DateTimePicker
          calendarClassName="rounded-2xl"
          format="MM dd, yyyy"
          disableClock={true}
          onChange={(data) => handleFiltersChange(data, 'fromDate')}
          value={filters.fromDate}
        />
      </div>
      <div className="mb-2">
        <div className="pb-2">{t('common:to')}:</div>
        <DateTimePicker
          calendarClassName="rounded-2xl"
          format="MM dd, yyyy"
          disableClock={true}
          onChange={(data) => handleFiltersChange(data, 'toDate')}
          value={filters.toDate}
        />
      </div>
      <div className="flex justify-between items-center">
        <div>{t('common:containManual')}</div>
        <Checkbox
          checked={filters.manualReport}
          onChange={(e) => handleFiltersChange(e.target.checked, 'manualReport')}
        />
      </div>
      <div className="flex justify-between items-center">
        <div>{t('common:containReports')}</div>
        <Checkbox
          checked={filters.aiReport}
          onChange={(e) => handleFiltersChange(e.target.checked, 'aiReport')}
        />
      </div>
      <div className="width-filter">
        <div>
          <div className="width-inner pb-2 pt-2">
            <ReactSelect
              isClearable
              label="Select contamination"
              options={recorderOptionsData}
              value={setSelectValue(filters.recorderUser, recorderOptionsData)}
              onChange={(selectedOption) =>
                handleFiltersChange(selectedOption ? selectedOption.value : null, 'recorderUser')
              }
              placeholder={t('common:userVideo')}
              className="min-w-52"
              classNamePrefix="select-companies"
            ></ReactSelect>
          </div>
        </div>
      </div>
      <div className="width-filter">
        <div>
          <div className="width-inner pb-2 pt-2">
            <ReactSelect
              isClearable
              label="Select contamination"
              options={reporterOptionsData}
              value={setSelectValue(filters.reporterUser, reporterOptionsData)}
              onChange={(selectedOption) =>
                handleFiltersChange(selectedOption ? selectedOption.value : null, 'reporterUser')
              }
              placeholder={t('common:userReport')}
              className="min-w-52"
              classNamePrefix="select-companies"
            ></ReactSelect>
          </div>
        </div>
      </div>
      <div className="width-filter">
        <div>
          <div className="width-inner pb-2 pt-2">
            <ReactSelect
              isClearable
              label="Select producer"
              options={producersOptionsData}
              value={setSelectValue(filters.selectedProducer, producersOptionsData)}
              onChange={handleProducerChange}
              placeholder={t('common:oem')}
              className="min-w-52"
              classNamePrefix="select-companies"
            ></ReactSelect>
          </div>
        </div>
      </div>
      <div className="width-filter">
        <div>
          <div className="width-inner pb-2 pt-2">
            <ReactSelect
              isClearable
              label="Select model"
              options={modelsOptionsData}
              value={setSelectValue(filters.selectedModel, modelsOptionsData)}
              onChange={handleModelChange}
              placeholder={t('common:model')}
              className="min-w-52"
              classNamePrefix="select-companies"
            ></ReactSelect>
          </div>
        </div>
      </div>
      <div className="width-filter">
        <div>
          <div className="width-inner pb-2 pt-2">
            <ReactSelect
              isClearable
              label="Select contamination"
              options={serialsOptionsData}
              value={setSelectValue(filters.selectedSerial, serialsOptionsData)}
              onChange={handleSerialChange}
              placeholder={t('common:serial')}
              className="min-w-52"
              classNamePrefix="select-companies"
            ></ReactSelect>
          </div>
        </div>
      </div>
      <div className="width-filter">
        <div>
          <div className="width-inner pb-2 pt-2">
            <ReactSelect
              isMulti
              label="Select contamination"
              options={contaminationOptions}
              value={selectedContamination}
              onChange={handleContaminationsSelect}
              placeholder={t('common:issue')}
              className="basic-multi-select min-w-52"
              classNamePrefix="select-companies"
            ></ReactSelect>
          </div>
        </div>
      </div>
      {companyType === 1 && (
        <div className="width-filter">
          <div>
            <div className="width-inner pb-2 pt-2">
              <ReactSelect
                isClearable
                label="Select customer"
                options={ownersOptionsData}
                value={setSelectValue(filters.ownerId, ownersOptionsData)}
                onChange={(selectedOption) =>
                  handleFiltersChange(selectedOption ? selectedOption.value : null, 'ownerId')
                }
                placeholder={t('common:customer')}
                className="basic-multi-select min-w-52"
                classNamePrefix="select-companies"
              ></ReactSelect>
            </div>
          </div>
        </div>
      )}

      <div className="width-filter">
        <div>
          <div className="width-inner pb-2 pt-2">
            <ReactSelect
              isClearable
              label="Select customer"
              options={devices}
              value={setSelectValue(filters.selectedDevice, devices)}
              onChange={(selectedOption) =>
                handleFiltersChange(selectedOption ? selectedOption.value : null, 'selectedDevice')
              }
              placeholder={t('common:verifyAI')}
              className="basic-multi-select min-w-52"
              classNamePrefix="select-companies"
            ></ReactSelect>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
