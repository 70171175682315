import isEqual from 'lodash.isequal';
import { format, endOfDay } from 'date-fns';

import { capFirstLetterInEveryWord } from '../utils/strings';

export function binarySearch(arr, target) {
  let start = 0;
  let end = arr.length - 1;

  while (end >= start) {
    let mid = Math.floor((start + end) / 2);
    if (arr[mid] == target) {
      return mid;
    }
    if (arr[mid] > target) {
      end = mid - 1;
    } else {
      start = mid + 1;
    }
  }

  if (end < 0) {
    end = 0;
  }
  return end;
}

export const getMediaTime = () =>
  new Promise((resolve) => {
    const video = document.getElementsByTagName('video')[0];

    if (!video) return;
    video.requestVideoFrameCallback((now, metadata) => {
      resolve(metadata.mediaTime);
    });
  });

export const jumpNextFrame = async (direction, firstMediaTime) => {
  const video = document.getElementsByTagName('video')[0];
  if (!video) return;

  if (
    (video.duration === video.currentTime || video.duration - video.currentTime < 0.04) &&
    direction !== 'back'
  ) {
    return;
  }
  // force rerender
  video.currentTime += 0.0001;
  video.currentTime -= 0.0001;

  // get current frame time
  // const firstMediaTime = await getMediaTime();

  if (!firstMediaTime && direction === 'back') {
    return;
  }
  const jump = direction === 'back' ? -0.01 : 0.01;
  for (;;) {
    // now adjust video's current time until actual frame time changes
    video.currentTime += jump;

    if ((await getMediaTime()) !== firstMediaTime) break;
  }
};

export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const filterUrl = (url, toRemove) => {
  return url
    .split('/')
    .filter((c) => c !== toRemove)
    .join('/');
};

export const generateUrl = (filters) => {
  const {
    manualReport,
    aiReport,
    fromDate,
    toDate,
    reporterUser,
    recorderUser,
    selectedProducer,
    selectedModel,
    selectedSerial,
    contaminationPath,
    ownerId,
    selectedDevice
  } = filters;
  let queryParams = [];

  if (manualReport) queryParams.push(`rep_man=true`);
  if (aiReport) queryParams.push(`rep_ai=true`);
  if (fromDate) queryParams.push(`timeFrom=${format(fromDate, 'yyyy-MM-dd HH:mm')}`);
  if (toDate) queryParams.push(`timeTo=${format(endOfDay(toDate), 'yyyy-MM-dd HH:mm')}`);
  if (reporterUser) queryParams.push(`gen_by=${reporterUser}`);
  if (recorderUser) queryParams.push(`recorded_by=${recorderUser}`);
  if (selectedProducer) queryParams.push(`endosc_prod=${selectedProducer}`);
  if (selectedModel) queryParams.push(`endosc_model=${selectedModel}`);
  if (selectedSerial) queryParams.push(`endosc_serial=${selectedSerial}`);
  if (contaminationPath) {
    let allContaminationPath = 'cont_spec=' + contaminationPath;
    queryParams.push(allContaminationPath);
  }
  if (ownerId) queryParams.push(`customer=${ownerId}`);
  if (selectedDevice) queryParams.push(`hw_id=${selectedDevice}`);

  return queryParams;
};

export const formOwnerOptions = (ownersObject) => {
  const arr = [];

  // Check if ownersObject is defined and not null
  if (ownersObject && Array.isArray(ownersObject)) {
    ownersObject.forEach((owner) => {
      if (owner && owner.value && owner.label) {
        arr.push({ value: owner.value, label: owner.label });
      }
    });
  }

  return arr;
};

export const formUserOptions = (users) => {
  const arr = [];

  if (!users) {
    return arr;
  }
  users.forEach((user) => {
    if (!arr.map((item) => item.value).includes(user.id)) {
      arr.push({
        value: user.id,
        label: user.firstName + ' ' + user.lastName
      });
    }
  });
  return arr;
};

export const formSelectOptions = (items) => {
  const arr = [];
  items.forEach((item) => {
    arr.push({ value: item, label: item });
  });
  return arr;
};

export const formEndoscopes = (receivedData) => {
  if (!receivedData) {
    return [];
  }

  return receivedData
    .map((item) => {
      if (
        item.endoscope.producer !== null &&
        item.endoscope.model !== null &&
        item.endoscope.serial !== null
      ) {
        return {
          producer: item.endoscope.producer,
          model: item.endoscope.model,
          serial: item.endoscope.serial
        };
      } else {
        return null;
      }
    })
    .filter((endoscope) => endoscope !== null);
};

export const formOwners = (receivedData) => {
  if (!receivedData) {
    return [];
  }
  const listOwners = receivedData
    .map((item) => {
      if (item.endoscope.owner_name !== null && item.endoscope.owner_id !== null) {
        return {
          value: item.endoscope.owner_id,
          label: capFirstLetterInEveryWord(item.endoscope.owner_name)
        };
      } else {
        return null;
      }
    })
    .filter((owner) => owner !== null);

  const uniqueOwnersSet = new Set();

  // Filter out duplicate objects based on the 'value' property
  const uniqueOwners = listOwners.filter((owner) => {
    // Check if the value is already in the set
    if (!uniqueOwnersSet.has(owner.value)) {
      // If not, add it to the set and return true to keep it in the array
      uniqueOwnersSet.add(owner.value);
      return true;
    }
    // If the value is already in the set, return false to filter it out
    return false;
  });

  return uniqueOwners;
};

export const formContaminationData = (frames, fps, domainMax, mod, speed) => {
  const data = {};
  if (!frames || !fps) {
    return data;
  }

  for (const [key, value] of Object.entries(frames)) {
    const sec = Math.floor(+key / +fps).toString();
    // Depending on record mode, either seconds or cm will be used
    const position = mod === 'Auto' ? Math.floor((sec * speed) / 10) : sec;
    const group = Math.floor(+position / domainMax).toString();

    value?.forEach((c) => {
      if (!data[group] || !data[group][position] || !data[group][position][c.label]) {
        if (c.label) {
          const item = { [c.label]: { ...c, frame: key } };
          const groupSec = data[group] && data[group][position] ? data[group][position] : {};
          data[group] = { ...data[group], [position]: { ...groupSec, ...item } };
        }
      }
    });
  }

  return data;
};

export function renderTableData(json) {
  json.forEach((obj) => {
    let moveUpProducer = obj.endoscope.producer;
    let moveUpModel = obj.endoscope.model;
    let moveUpSerial = obj.endoscope.serial;
    obj.producer = `${moveUpProducer} ${moveUpModel} ${moveUpSerial}`;
  });
}

export const formDevices = (receivedData) => {
  let listDevices = [];

  if (!receivedData) {
    return listDevices;
  }

  listDevices = receivedData
    ?.filter((item, index) => {
      if (item.hardware_id && item.hardware_alias) {
        return (
          receivedData?.findIndex((c) => c.hardware_id === item.hardware_id && c.hardware_alias) ===
          index
        );
      }
    })
    ?.map((item) => ({
      value: item.hardware_id,
      label: item.hardware_alias
    }));

  return listDevices;
};

export const getVideoId = () => {
  return window?.location?.pathname?.split('/').pop();
};

export const getVideoType = () => {
  const pathName = window?.location?.pathname?.toString();

  return pathName.split('/')[2];
};

export const formUserAccess = (data) => {
  return Object.entries(data)
    .filter((c) => c[0] !== 'parentCompany' && c[1])
    .map((c) => c[0]);
};

export const generateSelectOptions = (data) => {
  const processedArray = data?.map((originalObject) => {
    const modifiedObject = {
      value: originalObject.id,
      label: originalObject.name
    };
    return modifiedObject;
  });
  return processedArray;
};

export const setSelectValue = (value, data) => {
  if (!data || !value) {
    return null;
  }

  if (data.length > 0) {
    if (data[0].options) {
      const option = data.find((c) => c.options.some((k) => isEqual(k.value, value)));

      return option?.options?.filter((c) => isEqual(c.value, value));
    }
  }

  return data.filter((c) => isEqual(c.value, value))[0] || null;
};

export const formDefaultCompanies = (access) => {
  const defaultCompanies = {};
  Object.entries(access).forEach((c) => {
    const item = c[1];
    defaultCompanies[item.id] = item.access;
  });
  return defaultCompanies;
};

export const normalizeCompanyData = (inputObject) => {
  const transformedArray = Object.keys(inputObject).map((key) => ({
    id: key,
    ...inputObject[key]
  }));
  return transformedArray;
};

export const cutLanguageCode = (lang) => {
  if (!lang) {
    return '';
  }
  return lang.split('-')[0];
};

export const reportStatus = (status) => {
  switch (status) {
    case 0:
      return 'G';
    case 1:
      return 'C';
    case 2:
      return 'D';
    case 3:
      return 'D+C';
    default:
      return '';
  }
};

export const colorToshowBylabel = (label, options) => {
  const option = options.find((option) => option.label === label);
  return option?.color || 'red';
};

export const optionByLabel = (label, options) => {
  const option = options.find((option) => option.label === label);
  return option;
};

export const createReletionEndoscope = (endoscopeObj, setProducers, setModels, setSerials) => {
  if (endoscopeObj === null) {
    let relEndoscope = {
      producers: [],
      models: [],
      serials: []
    };

    return relEndoscope;
  }

  if (endoscopeObj) {
    const relEndoscope = {
      producers: [],
      models: [],
      serials: []
    };
    endoscopeObj.forEach((endoscope) => {
      // Check if the producer already exists in relationEndoscope.producers
      const producerIndex = relEndoscope.producers.findIndex(
        (p) => p.producer === endoscope.producer
      );
      if (producerIndex === -1) {
        // If producer not found, create a new entry
        relEndoscope.producers.push({
          producer: endoscope.producer,
          models: [endoscope.model],
          serials: [endoscope.serial]
        });
      } else {
        // If producer already exists, update models and serials
        if (relEndoscope.producers[producerIndex].models !== null) {
          if (!relEndoscope.producers[producerIndex].models.includes(endoscope.model)) {
            relEndoscope.producers[producerIndex].models.push(endoscope.model);
          }
        }
        if (relEndoscope.producers[producerIndex].serials !== null) {
          if (!relEndoscope.producers[producerIndex].serials.includes(endoscope.serial)) {
            relEndoscope.producers[producerIndex].serials.push(endoscope.serial);
          }
        }
      }

      const modelIndex = relEndoscope.models.findIndex((p) => p.model === endoscope.model);
      if (modelIndex === -1) {
        relEndoscope.models.push({
          model: endoscope.model,
          producer: endoscope.producer,
          serials: [endoscope.serial]
        });
      } else {
        if (relEndoscope.models[modelIndex].serials !== null) {
          if (!relEndoscope.models[modelIndex].serials.includes(endoscope.serial)) {
            relEndoscope.models[modelIndex].serials.push(endoscope.serial);
          }
        }
      }

      const serialIndex = relEndoscope.serials.findIndex((p) => p.serial === endoscope.serial);
      if (serialIndex === -1) {
        relEndoscope.serials.push({
          serial: endoscope.serial,
          model: endoscope.model,
          producer: endoscope.producer
        });
      }
    });

    const producersList = [];
    relEndoscope.producers.forEach((item) => {
      producersList.push(item.producer);
    });

    setProducers(producersList);

    const modelList = [];
    relEndoscope.models.forEach((item) => {
      modelList.push(item.model);
    });
    setModels(modelList);

    const serialList = [];

    relEndoscope.serials.forEach((item) => {
      serialList.push(item.serial);
    });
    setSerials(serialList);

    return relEndoscope;
  }
};
