import { useEffect, useRef, useState } from 'react';
import { Switch } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { FaRegKeyboard } from 'react-icons/fa';
import cls from 'classnames';

import { getUser } from '../../utils/session';
import { fetchData } from '../../api/endoscopes';
import { getVideoId } from '../../utils/helpers';
import { getCoordinates } from '../../utils/numbers';
import { API_URL } from '../../helpers/constants';
import { endoscopesAtom } from '../../atoms/endoscopes';
import {
  videoTypeAtom,
  framesAtom,
  frameAtom,
  reportModeAtom,
  isReportingAtom,
  durationAtom,
  isPausedAtom,
  mediaTimeAtom,
  reportFramesAtom
} from '../../atoms/video';
import Tooltip from '../../components/Tooltip/Tooltip';
import ShortcutsInfo from '../../components/ShortcutsInfo/ShortcutsInfo';
import BoundedBoxes from '../BoundedBoxes';
import Controls from './Controls';
import Speed from './Speed';
import Box from '../Box';

const VideoPlayer = ({ isSidePanelOpen }) => {
  const user = getUser();
  const { t } = useTranslation(['common', 'buttons']);
  const [reportMode, setReportMode] = useAtom(reportModeAtom);
  const [isReporting, setIsReporting] = useAtom(isReportingAtom);
  const [data, setData] = useAtom(endoscopesAtom);
  const [videoType, setVideoType] = useAtom(videoTypeAtom);
  const [duration, setDuration] = useAtom(durationAtom);
  const [frames, setFrames] = useAtom(framesAtom);
  const [frame, setFrame] = useAtom(frameAtom);
  const [mediaTime, setMediaTime] = useAtom(mediaTimeAtom);
  const [reportFrames, setReportFrames] = useAtom(reportFramesAtom);

  const [isPaused, setIsPaused] = useAtom(isPausedAtom);

  const { fps } = data;
  const [isToggleVisible, setIsToggleVisible] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);

  const videoRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const [initialPoints, setInitialPoints] = useState(null);
  const [finalPoints, setFinalPoints] = useState(null);

  let videoUrl;
  const videoId = getVideoId();
  const video = document.querySelector('video');
  // const frame = (fps && video && Math.round(video.currentTime * +fps).toString()) || '';
  const offsetHeight = videoRef?.current?.offsetHeight || 0;
  const offsetWidth = videoRef?.current?.offsetWidth || 0;

  useEffect(() => {
    videoUrl = `${API_URL}/video/stream/${videoType}/${videoId}?token=${user?.access_token}` || '';

    let source = document.querySelector('video > source');

    source.src = videoUrl;

    source?.addEventListener('error', handleVideoLoadError);

    source.parentNode.load();

    // source.parentNode.play();

    if (video) {
      handleLoadMetadata();
    }

    return () => {
      source.removeEventListener('error', handleVideoLoadError);
    };
  }, [videoType, videoId]);

  useEffect(() => {
    let source = document.querySelector('video > source');
    source.parentNode?.addEventListener('loadeddata', handleVideoLoadSuccess);

    return () => {
      source.parentNode.removeEventListener('loadeddata', handleVideoLoadSuccess);
    };
  }, [isToggleVisible]);

  useEffect(() => {
    if (duration) {
      setDuration(0);
    }

    if (isToggleVisible) {
      setIsToggleVisible(false);
    }
    handleExitReportMode();
    fetchData(videoId, setData);
    setReportFrames({ id: null, frames: {} });

    return () => {
      setMediaTime(0);
      setFrame(0);
    };
  }, [videoId]);

  const handleVideoLoadError = (e) => {
    console.log('<source> video load error', videoType);
    // e does not contain anything useful -- https://html.spec.whatwg.org/multipage/media.html#event-source-error
    // e.target would be the <source> element
    // e.target.parentNode would be the <video> element
    // e.target.parentNode.error -- https://html.spec.whatwg.org/multipage/media.html#mediaerror
    // e.target.parentNode.networkState -- https://html.spec.whatwg.org/multipage/media.html#dom-media-networkstate

    if (e.target.parentNode.networkState !== 0 && videoType === 'wt') {
      setVideoType('wo');
    }
  };

  const handleVideoLoadSuccess = (e) => {
    setDuration(videoRef?.current?.duration);
    if (!isToggleVisible) {
      setIsToggleVisible(true);
    }
  };

  /* load and set actual video data */
  const handleLoadMetadata = () => {
    if (video && video.duration) {
      let currTime = video.currentTime;
      setCurrentTime(currTime);
    }
  };

  /* if user change duration slider position - update video data */
  const handleSeeked = (e) => {
    handleLoadMetadata();
  };

  const handleExitReportMode = () => {
    setFrames({});
    if (reportMode) {
      setReportMode(false);
    }
    if (isReporting) {
      setIsReporting(false);
    }
  };

  const handleMouseDown = (event) => {
    if (reportMode) {
      const curFrame = frames[frame];
      // User cannot select another bounded box without selecting a label of the previous one
      if (!curFrame || curFrame?.length === 0 || curFrame[curFrame?.length - 1]?.label) {
        if (!isReporting) {
          setIsReporting(true);
        }

        if (!videoRef.current.paused) {
          videoRef.current.pause();
        }

        const { offsetX, offsetY } = event.nativeEvent;
        // console.log('offsetX, offsetY', offsetX, offsetY);
        setInitialPoints({ x: offsetX, y: offsetY });
        setIsDrawing(true);
      }
    }
  };

  const handleMouseMove = (event) => {
    if (!isDrawing && !initialPoints) return;
    const { offsetX, offsetY } = event.nativeEvent;
    setFinalPoints({ x: offsetX, y: offsetY });
  };

  const handleMouseUp = () => {
    if (isDrawing && initialPoints && finalPoints) {
      const newRectangle = {
        x_min: getCoordinates(initialPoints.x, offsetWidth),
        y_min: getCoordinates(initialPoints.y, offsetHeight),
        x_max: getCoordinates(finalPoints.x, offsetWidth),
        y_max: getCoordinates(finalPoints.y, offsetHeight)
      };
      setFrames((prevRectangles) => ({
        ...prevRectangles,
        [frame]: [...(prevRectangles[frame] || []), newRectangle]
      }));
    }
    setIsDrawing(false);
    setInitialPoints(null);
    setFinalPoints(null);
  };

  const handleKeydown = (e) => {
    // native events in controls
    if (e.key == ' ' || e.keyCode === 32 || e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      // e.preventDefault();
      e.stopPropagation();

      // e.nativeEvent.stopImmediatePropagation();
    }
  };

  const frameCounter = (time, metadata) => {
    let count = Math.round(metadata.mediaTime * fps);
    if (fps && frame != count) {
      setFrame(count);
      setMediaTime(metadata.mediaTime);
    }
    // Capture code here.
    video.requestVideoFrameCallback(frameCounter);
  };
  video?.requestVideoFrameCallback(frameCounter);

  return (
    <div className="sticky w-full top-[113px]">
      <div className="flex justify-between items-center h-[40px] my-2">
        <div className="w-1/2">
          {isToggleVisible && (
            <Switch
              onChange={(e) => setVideoType((videoType) => (videoType === 'wt' ? 'wo' : 'wt'))}
              checked={videoType === 'wt'}
              label={t('common:showPrediction')}
            />
          )}
        </div>
        <Tooltip
          overlay={<ShortcutsInfo />}
          trigger={['hover', 'focus', 'click']}
          // mouseEnterDelay={2}
          // mouseLeaveDelay={0.5}
          placement="leftTop"
          id="tooltip1"
        >
          <FaRegKeyboard className="mr-3 cursor-pointer h-5 w-5" />
        </Tooltip>
      </div>
      <div
        className={cls(`relative player_container h-[calc(40vw-1rem)]`, {
          '[&&]:h-[calc(30vw-1rem)]': isSidePanelOpen
        })}
      >
        <video
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onKeyDown={handleKeydown}
          ref={videoRef}
          className="shadow-lg player"
          controls={!isReporting}
          muted
          onSeeked={handleSeeked}
          onPause={handleLoadMetadata}
          // onPlay={handlePlay}
          style={reportMode ? { cursor: 'crosshair' } : {}}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
        {reportMode && video?.paused && frames[frame]?.length > 0 && (
          <BoundedBoxes offsetWidth={offsetWidth} offsetHeight={offsetHeight} frame={frame} />
        )}

        {!isPaused && videoRef?.current?.playbackRate && (
          <Speed videoRef={videoRef} speed={videoRef?.current?.playbackRate} />
        )}
        {initialPoints && finalPoints && isDrawing && (
          <Box
            left={finalPoints.x - initialPoints.x < 0 ? finalPoints.x : initialPoints.x}
            top={finalPoints.y - initialPoints.y < 0 ? finalPoints.y : initialPoints.y}
            width={Math.abs(finalPoints.x - initialPoints.x)}
            height={Math.abs(finalPoints.y - initialPoints.y)}
          />
        )}
      </div>
      {videoRef?.current && (
        <Controls videoRef={videoRef} handleLoadMetadata={handleLoadMetadata} />
      )}
    </div>
  );
};

export default VideoPlayer;
